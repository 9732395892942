var render = function render() {
  var _vm$userHeadStoryList, _vm$userHeadStoryList2, _vm$userHeadStoryList3, _vm$userHeadStoryList4, _vm$userHeadStoryList5, _vm$userHeadStoryList6, _vm$userHeadStoryList7, _vm$userHeadStoryList8, _vm$userHeadStoryList9, _vm$userHeadStoryList10, _vm$userHeadStoryList11, _vm$userHeadStoryList12, _vm$userHeadStoryList13, _vm$userHeadStoryList14, _vm$userHeadStoryList15;
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-userStory",
    attrs: {
      id: "content"
    }
  }, [false ? _c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: _vm.title + "uploadImage/" + _vm.news_list.cnHomeImage
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: _vm.title + "uploadImage/" + _vm.news_list.enHomeImage
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: _vm.title + "uploadImage/" + _vm.news_list.trHomeImage
    }
  }) : _vm._e()]) : _vm._e(), _vm.device == "mobile" && false ? _c("div", {
    staticClass: "aboutBannerBox"
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/user_story_banner_mobile.jpg")
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/user_story_banner_mobile_en.jpg")
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "aboutBanner",
    attrs: {
      src: require("../assets/banner/user_story_banner_mobile_tr.jpg")
    }
  }) : _vm._e()]) : _vm._e(), false ? _c("div", {
    staticClass: "content user-nav"
  }, [_c("div", {
    staticClass: "content-header"
  }, [_c("div", {
    staticClass: "concept-content",
    attrs: {
      id: "our_target"
    }
  }, [_c("div", {
    staticClass: "left animate__animated animate__fadeInLeft animate__slow"
  }, _vm._l(_vm.aLinks, function (item, index) {
    return _c("a", {
      key: index,
      staticClass: "navItem",
      class: {
        current: index == _vm.currentIndex
      },
      on: {
        click: function ($event) {
          return _vm.scrollIntoView(item.id, index);
        }
      }
    }, [_vm._m(0, true), _c("span", [_vm._v(_vm._s(item.name[parseInt(_vm.language - 1)]))])]);
  }), 0), _c("div", {
    staticClass: "right animate__animated animate__fadeInUp animate__slow"
  }, [_c("p", {
    staticClass: "intro-content"
  }, [_vm._v(_vm._s(_vm.mapText.our_target[parseInt(_vm.language - 1)]))])])])])]) : _vm._e(), _c("div", {
    staticClass: "companyBlock",
    staticStyle: {
      "padding-bottom": "2em"
    }
  }, [_c("div", {
    staticClass: "companyBlock-about"
  }, [_c("div", {
    ref: "user_story_title",
    staticClass: "companyBlock-hd animate__animated animate__slow",
    class: {
      "animate__fadeInUp margin-top-6em": _vm.device == "mobile"
    }
  }, [_vm.language == 1 ? _c("h2", [_vm._v("用户故事")]) : _vm._e(), _vm.language == 2 ? _c("h2", [_vm._v("PATIENT STORIES")]) : _vm._e(), _vm.language == 3 ? _c("h2", [_vm._v("用戶故事")]) : _vm._e(), _c("div", {
    staticClass: "line"
  })]), _c("div", {
    staticClass: "userBox"
  }, [_c("div", {
    ref: "user_story_cwq",
    staticClass: "story cwqStory animate__animated animate__slow",
    class: {
      "display-none": _vm.device == "pc",
      animate__fadeInLeft: _vm.device == "mobile"
    },
    attrs: {
      id: "user-cwq"
    }
  }, [_c("div", {
    staticClass: "type-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.cwq_user[parseInt(_vm.language - 1)]))])]), _c("div", {
    staticClass: "title"
  }, [_c("h4", [_vm._v(_vm._s((_vm$userHeadStoryList = _vm.userHeadStoryList[0]) === null || _vm$userHeadStoryList === void 0 ? void 0 : _vm$userHeadStoryList.storyTitle))]), _c("pre", [_vm._v(_vm._s((_vm$userHeadStoryList2 = _vm.userHeadStoryList[0]) === null || _vm$userHeadStoryList2 === void 0 ? void 0 : _vm$userHeadStoryList2.storyJianjie))]), _c("router-link", {
    attrs: {
      to: {
        path: "storyHeadDetail",
        query: {
          id: (_vm$userHeadStoryList3 = _vm.userHeadStoryList[0]) === null || _vm$userHeadStoryList3 === void 0 ? void 0 : _vm$userHeadStoryList3.id
        }
      }
    }
  }, [_vm._v(_vm._s((_vm$userHeadStoryList4 = _vm.userHeadStoryList[0]) === null || _vm$userHeadStoryList4 === void 0 ? void 0 : _vm$userHeadStoryList4.storyButton))]), _c("div", {
    staticClass: "language == 2 ? transverse-line-en : transverse-line"
  }), _c("div", {
    staticClass: "img"
  }, [_c("img", {
    attrs: {
      src: _vm.title + ((_vm$userHeadStoryList5 = _vm.userHeadStoryList[0]) === null || _vm$userHeadStoryList5 === void 0 ? void 0 : _vm$userHeadStoryList5.storyPic)
    }
  })])], 1), _c("ul", _vm._l(_vm.userStoryList.cwq, function (items, index) {
    return _c("li", {
      key: index
    }, [_c("router-link", {
      attrs: {
        to: {
          path: "storyDetail",
          query: {
            id: items.id
          }
        }
      }
    }, [_c("div", {
      staticClass: "left"
    }, [_c("img", {
      attrs: {
        src: _vm.title + items.storyPoster
      }
    })]), _c("div", {
      staticClass: "middle"
    }, [_c("h2", [_vm._v(_vm._s(items.storyTitle))]), _c("p", [_vm._v(_vm._s(items.storyTntroduction))])]), _vm.language == 1 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" 查看完整故事 "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e(), _vm.language == 2 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" See the full story "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e(), _vm.language == 3 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" 查看完整故事 "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e()])], 1);
  }), 0)]), _c("div", {
    ref: "user_story_uu",
    staticClass: "story uugStory animate__animated animate__slow display-none",
    attrs: {
      id: "user_uu"
    }
  }, [_c("div", {
    staticClass: "type-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.uu_user[parseInt(_vm.language - 1)]))])]), _c("div", {
    staticClass: "title"
  }, [_c("h4", [_vm._v(_vm._s((_vm$userHeadStoryList6 = _vm.userHeadStoryList[1]) === null || _vm$userHeadStoryList6 === void 0 ? void 0 : _vm$userHeadStoryList6.storyTitle))]), _c("pre", [_vm._v(_vm._s((_vm$userHeadStoryList7 = _vm.userHeadStoryList[1]) === null || _vm$userHeadStoryList7 === void 0 ? void 0 : _vm$userHeadStoryList7.storyJianjie))]), _c("router-link", {
    attrs: {
      to: {
        path: "storyHeadDetail",
        query: {
          id: (_vm$userHeadStoryList8 = _vm.userHeadStoryList[1]) === null || _vm$userHeadStoryList8 === void 0 ? void 0 : _vm$userHeadStoryList8.id
        }
      }
    }
  }, [_vm._v(_vm._s((_vm$userHeadStoryList9 = _vm.userHeadStoryList[1]) === null || _vm$userHeadStoryList9 === void 0 ? void 0 : _vm$userHeadStoryList9.storyButton))]), _c("div", {
    staticClass: "language == 2 ? transverse-line-en : transverse-line"
  }), _c("div", {
    staticClass: "img"
  }, [_c("img", {
    attrs: {
      src: _vm.title + ((_vm$userHeadStoryList10 = _vm.userHeadStoryList[1]) === null || _vm$userHeadStoryList10 === void 0 ? void 0 : _vm$userHeadStoryList10.storyPic)
    }
  })])], 1), _c("ul", _vm._l(_vm.userStoryList.uu, function (items, index) {
    return _c("li", {
      key: index
    }, [_c("router-link", {
      attrs: {
        to: {
          path: "storyDetail",
          query: {
            id: items.id
          }
        }
      }
    }, [_c("div", {
      staticClass: "left"
    }, [_c("img", {
      attrs: {
        src: _vm.title + items.storyPoster
      }
    })]), _c("div", {
      staticClass: "middle"
    }, [_c("h2", [_vm._v(_vm._s(items.storyTitle))]), _c("p", [_vm._v(_vm._s(items.storyTntroduction))])]), _vm.language == 1 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" 查看完整故事 "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e(), _vm.language == 2 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" See the full story "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e(), _vm.language == 3 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" 查看完整故事 "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e()])], 1);
  }), 0)]), _c("div", {
    ref: "user_story_pp",
    staticClass: "story ppgStory animate__animated animate__slow display-none",
    attrs: {
      id: "user_pp"
    }
  }, [_c("div", {
    staticClass: "type-title"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.pp_user[parseInt(_vm.language - 1)]))])]), _c("div", {
    staticClass: "title"
  }, [_c("h4", [_vm._v(_vm._s((_vm$userHeadStoryList11 = _vm.userHeadStoryList[2]) === null || _vm$userHeadStoryList11 === void 0 ? void 0 : _vm$userHeadStoryList11.storyTitle))]), _c("pre", [_vm._v(_vm._s((_vm$userHeadStoryList12 = _vm.userHeadStoryList[2]) === null || _vm$userHeadStoryList12 === void 0 ? void 0 : _vm$userHeadStoryList12.storyJianjie))]), _c("router-link", {
    attrs: {
      to: {
        path: "storyHeadDetail",
        query: {
          id: (_vm$userHeadStoryList13 = _vm.userHeadStoryList[2]) === null || _vm$userHeadStoryList13 === void 0 ? void 0 : _vm$userHeadStoryList13.id
        }
      }
    }
  }, [_vm._v(_vm._s((_vm$userHeadStoryList14 = _vm.userHeadStoryList[2]) === null || _vm$userHeadStoryList14 === void 0 ? void 0 : _vm$userHeadStoryList14.storyButton))]), _c("div", {
    staticClass: "language == 2 ? transverse-line-en : transverse-line"
  }), _c("div", {
    staticClass: "img"
  }, [_c("img", {
    attrs: {
      src: _vm.title + ((_vm$userHeadStoryList15 = _vm.userHeadStoryList[2]) === null || _vm$userHeadStoryList15 === void 0 ? void 0 : _vm$userHeadStoryList15.storyPic)
    }
  })])], 1), _c("ul", _vm._l(_vm.userStoryList.pupu, function (items, index) {
    return _c("li", {
      key: index
    }, [_c("router-link", {
      attrs: {
        to: {
          path: "storyDetail",
          query: {
            id: items.id
          }
        }
      }
    }, [_c("div", {
      staticClass: "left"
    }, [_c("img", {
      attrs: {
        src: _vm.title + items.storyPoster
      }
    })]), _c("div", {
      staticClass: "middle"
    }, [_c("h2", [_vm._v(_vm._s(items.storyTitle))]), _c("p", [_vm._v(_vm._s(items.storyTntroduction))])]), _vm.language == 1 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" 查看完整故事 "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e(), _vm.language == 2 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" See the full story "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e(), _vm.language == 3 ? _c("div", {
      staticClass: "right"
    }, [_vm._v(" 查看完整故事 "), _c("img", {
      attrs: {
        src: require("../assets/go.png")
      }
    })]) : _vm._e()])], 1);
  }), 0)])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "circle"
  }, [_c("div", {
    staticClass: "inner"
  }), _c("div", {
    staticClass: "outer"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };